<template>
  <div>
    <SkelletonHome v-if="homeConfig.homeUserLoading || loginStore.showPopupLogin" />
    <router-view v-show="!homeConfig.homeUserLoading" />
    <confirmationDialog/>
  </div>
</template>

<script>
import SkelletonHome from "./components/home/SkelletonHome.vue";
import { useHomeConfig } from "./store/homeConfigStore.js";
import { useLoginStore } from "@/store/loginStore";
import confirmationDialog from "@/components/globalWeb/confirmationDialog.vue";

export default {
  name: "App",
  components: {
    SkelletonHome,
    confirmationDialog,
  },

  data() {
    const homeConfig = useHomeConfig();
    const loginStore = useLoginStore();
    return {
      homeConfig,
      loginStore,
    };
  },
};
</script>

<style>
*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

*::-webkit-scrollbar-track {
  background-color: rgb(216, 216, 216);
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(145, 144, 144);
  border-radius: 10px;
}

.sidebar {
  z-index: 99999;
}
</style>